// COMMONSTYLES.SCSS

@import "functions";
@import "variables";
@import "mixins";


/* ORIGINAL GLOBAL STYLES
-------------------------------------------------- */
/* FIXED FOOTER SETTINGS
-------------------------------------------------- */
html {
	position: relative;
	min-height: 100%;
	z-index: 1;
}


body {
	// background-color: #fff;
	/* Global default text color */
	// background-color: #666;
	color: #666;
	/* Margin bottom fot footer height */
	margin-bottom: 50px;
	/* Padding top for default header height */
	// padding-top: 90px;
	@media (max-width: 768px) {
		// padding-top: 80px;
	}
	@media (max-width: 575px) {
		// padding-top: 80px;
	}
}

// button:focus {
// 	outline: 0;
// }

strong {
	font-weight: normal;
}

em {
	font-style: normal
}
.text-s {
	font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

.text-ss {
	font-family:-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
}


/* BREADCRUMBS MODULE
------------------------------ */
.breadcrumbs {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	.breadcrumb {
		margin-bottom: 0;
		background-color: #fff;
		padding-top: 40px;
		padding-bottom: 10px;
	}
	.breadcrumb-item {
		line-height: 1.0;
		font-size: .8125rem;
		+ .breadcrumb-item::before {
			content: "";
			color: #666;
			padding-right: .75rem;
		}
	&.breadcrumb-item::after {
		content: ">";
		color: #666;
		padding-left: .75rem;
	}
	&.bread-root {
		+ .breadcrumb-item::before {
			content: "|";
			color: #666;
		}
	&.breadcrumb-item::after {
		content: "";
		padding-left: .25rem;
	}
	}
	&.bread-home {
		+ .breadcrumb-item::before {
			content: "|";
			color: #666;
		}
	&.breadcrumb-item::after {
		content: "";
		padding-left: .25rem;
	}
	}
		a {
			color: #666;
		}
	&.active {
		color: #000;
	&.breadcrumb-item::after {
			content: "";
			color: #666;
			padding-left: .5rem;
	}
	&.breadcrumb-item::before {
			padding-left: 0;
	}
	}
	}
}

/* FADE IN SCROLL TOP
------------------------------ */
#scrolltop {
	position: fixed;
	bottom: 68px;
	right: 10px;
	z-index: 1000;
	a {
		background: #F2F93B;
		text-decoration: none;
		color: #fff;
		width: 48px;
		height: 48px;
		padding: 18px 14px;
		text-align: center;
		display: block;
		border-radius: 50%;
		opacity: 0.8;
	&:hover {
		text-decoration: none;
		background: #F2F93B;
		opacity: 1.0;
	}
	}
	.arrow {
		width: 20px;
		height: 20px;
		border: 5px solid;
		border-color: #4665A2 #4665A2 transparent transparent;
		transform: rotate(-45deg);
	}
}

/* NAVIBAR SETTINGS overwrite bootstrap default
-------------------------------------------------- */
.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 0 0;

	> .container,
	> .container-fluid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		position: relative;
	}
	h1 {
		margin: 0;
		padding-bottom: 0;
		display: inline;
		line-height: 1.0;
	}
&.bg-light {
	background-color: #fff !important;
}
&.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
}


.navbar-brand {
	padding-top: 1.125rem;
	padding-left: .75rem;
	margin-right: 0;
	padding-bottom: 20px;
}

.dropdown-toggle {
	@media (min-width: 992px) {
		&:after {
			content: none;
		}
	}
}

@media (min-width: 991px) {
	.nav-item {
		form {
			.form-control {
				width: 116px;
			}
		}
	}
}

.nav-item {
	// padding: 0 0;
&.nav-search {
	background-color: #8DABCC;
	padding-top: 34px;
	padding: 38px 24px 14px 14px;
	form {
		.form-control {
			// width: 116px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right: 0;
			border-color: #8DABCC;
		}
		.input-group-btn {

		}
		.btn-default {
			width: 22px;
			min-height: 38px;
			background: #fff url(../images/common/icon_search_b.png) 10% 50% no-repeat;
			background-size: 90%;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-color: #8DABCC;
			border-left: 0;
		&:hover {
			background: #fff url(../images/common/icon_search_db.png) 10% 50% no-repeat;
		}
		}
		@media (max-width: 575px) {
		.form-inline {
			.input-group {
				max-width: 100% !important;
			}
		}
		}
	}
}


}



.nav-link {
	// padding: 0 0;
	font-size: .9375rem;
	font-weight: bold;
	color: #333;
	margin-bottom: 0;
	line-height: 1.0;
	border-left: 1px solid #666;
	@media (max-width: 991px) {
		border-left: none;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg .navbar-nav .nav-link {
			padding-right: 0;
			padding-left: 0;
			padding: 55px 22px 6px 8px;
	}

}


@media (max-width: 1199px) {
	body {
		// padding-top: 140px;
	}
	.navbar {
		.navbar-brand {
			padding-top: .8rem;
			padding-left: 0;
			padding-bottom: 11px;
			margin: 0 auto;
		}
		> .container {
			flex-direction: column;
		}
	}

	.navbar-nav {
		.nav-item {
			.nav-link {
				padding: 16px 22px 16px 8px;
			}
		&:first-of-type {
			.nav-link {
				border-left: none;
			}
		}
		}
	}

	.nav-item {
	&.nav-search {
		padding: 8px 14px 8px 14px;
	}
	}

}


@media (min-width: 992px) and (max-width: 1199px) {
	.navbar {
		#header-nav {
			min-height: 140px;
			width: 100%;
			.navbar-nav {
				margin-top: 92px;
				width: 100%;
				flex-basis: 100%;
				justify-content: space-between;
				.nav-item {
					.nav-link {
						padding: 20px 22px 19px 8px;
					}
				}
			}
		}
		.navbar-brand {
			position: absolute;
			top: 10px;
			left: 50%;
			right: 50%;
			margin-left: -(245px /2);
			height: 51px;
			z-index: 800;
		}
	}

	.navbar {
		padding-top: 0;
	}

}


// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
	body {
		// padding-top: 72px;
	}
	.navbar {
		> .container {
			flex-direction: row;
		}
		.navbar-brand {
			padding-top: .625rem;
			padding-left: 1rem;
		}

		.navbar-collapse {
			.navbar-nav {
				margin-top: 0;
				.nav-link {
					padding-left: 1.5rem;
					padding-right: 1.5rem;
				}
			}
		}
	}
	.navbar-toggler {
		// margin-top: .75rem;
	}
}


// Dark links against a light background
.navbar-light {

	.navbar-nav {
		.nav-link {
			color: #666;

			@include hover-focus {
				color: #8dabcc;
				border-left-color: #8dabcc;
			}

		}

		.show > .nav-link,
		.active > .nav-link,
		.nav-link.show,
		.nav-link.active {
			color: #8dabcc;
		}
	}

	.navbar-toggler {
		border: 3px solid #4665A2;
		border-radius: 0;
		margin-right: 1rem;
		color: #4665A2;
		border-color: #4665A2;
		background: transparent;
	}

	.navbar-toggler-icon {
		background-image: url(../images/common/toggler-icon-bg.svg);
	}

	.navbar-text {
		color: #666;
	}
}

/* DROPDOWN OVERWRITE
------------------------------ */
// The dropdown menu
.dropdown-menu {
	padding: 0 0;
	// margin: $dropdown-spacer 0 0; // override default ul
	margin: 0 0 0;
	font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
	color: #fff;
	background-color: #666;
	opacity: 0.8;
	border: none;
	border-radius: 0;
}

.dropdown-item {
	color: #fff;
	font-size: .9375rem;
  // padding: .25rem 1.5rem; // default
	padding: .5rem $dropdown-item-padding-x;

	@media (max-width: 991px) {
		padding: 1rem 2.5rem;
	}

	@include hover-focus {
		// color: $dropdown-link-hover-color;
		color: #333;
		text-decoration: none;
		@include gradient-bg(#fff);
	}

	&.active,
	&:active {
		// color: $dropdown-link-active-color;
		color: #333;
		text-decoration: none;
		@include gradient-bg(#fff);
	}

}



/* STICKEY FOOTER SETTINGS
-------------------------------------------------- */
footer.footer {
	height: 300px;
	background-color: #fff;
	color: #666;
	position: relative;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	@media (max-width: 991px) {
		height: auto;
	}
}

.footer-contact {
	background-color: #8DABCC;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	ul {
		padding-left: 217px;
		list-style: none;
		li {
			height: 40px;
			line-height: 40px;
			color: #fff;
			font-size: 1.375rem;
		}
		a {
			color: #fff;
			&:hover,
			&:focus {
				text-decoration: none;
				cursor: pointer;
			}
		}
		@media (max-width: 991px) {
			padding-left: 0;
			li {
				text-align: center;
				font-size: 1.25rem;
			}
		}
	}
}

.footer-profiles {
	padding-top: 2rem;
		@media (max-width: 990px) {
			padding-top: 0;
		}
	.footer-id {
		padding-left: 10px;
		@media (max-width: 990px) {
			padding-left: 2rem;
		}
	}
	.footer-logo {
		margin-bottom: 1.25rem;
		@media (max-width: 990px) {
			display: none;
			margin-bottom: 0;
		}
	}
	.footer-address {
		font-size: .8125rem;
		font-weight: bold;
		margin-bottom: .875rem;
			@media (max-width: 991px) {
				margin-bottom: 2.5rem;
			}
		.fa-cname {
			display: block;
		}
	}
	.footer-sitemap {
		@media (max-width: 991px) {
			display: none;
		}
		font-size: .8125rem;
		p {
			padding: 0;
			margin: 0;
			line-height: 2.0;
			font-weight: bold;
			padding-bottom: .5rem;
		}
		a {
			color: #333;
		}
		ul {
			padding-left: 0;
			list-style: none;
			li {
				line-height: 2.0;
			&.col-12 {
				padding-left: 0;
			}
			}
		&.no-child {
			li {
				font-weight: bold;
			}
		}
		}
	}
}

.stickey-footer {
	height: 50px;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 1200;
}

.footer-copy {
	height: 30px;
	text-align: center;
	position: absolute;
	bottom: 0;
	width: 100%;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		padding-top: 10px;
		// background-color: #fff;
		// opacity: 0.9;
	}
	p {
		line-height: 1.0;
		font-size: .75rem;
		// margin-bottom: 10px;
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

.footer-links {
	height: 50px;
	line-height: 50px;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	.flink {
		text-align: center;
		a {
			font-size: .9375rem;
			margin: 0;
			padding: 0;
			height: 50px;
			line-height: 50px;
			font-weight: bold;
			color: #fff;
			display: inline-block;
			width: 100%;
		&:hover {
			text-decoration: none;
		}
		}
	&.flink-faq {
		background-color: #4665A2;
		z-index: 1050;
	}
	&.flink-inquiry {
		background-color: #037;
		z-index: 1051;
	}
	}
}

.flbg {
	height: 50px;
	margin-top: -50px;
	z-index: 20;
}
.flbg-faq {
	background-color: #4665A2;
	z-index: 20;
}

.flbg-inquiry {
	background-color: #037;
	z-index: 20;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
/* Carousel base class */
.carousel {
	margin-bottom: 4rem;
	background-color: #000;
}

.carousel-caption {
	z-index: 10;
	top: 10rem;
}

/* Declare heights because of positioning of img element */
.carousel-item {
	img {
		display: block;
		margin: 0 auto;
		max-width: 100%;
	}
	.slideimage {
		img {
			max-width: 100%;
		}
	}
	.slidecaption {
		max-width: 400px;
	}
}




.page-hero {
	background-color: #8DABCC;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	@media (max-width: 1199px) {
		.hero-image {
			img {
				max-width: 100%;
			}
		}
	}
	.hero-title {
		background-color: #4665A2;
		color: #fff;
		padding-top: 8.75rem;
		text-align: center;
		.class-title {
			font-size: 1.875rem;
			margin-bottom: .75rem;
		}
		.page-title {
			font-size: 1rem;
			font-weight: bold;
			display: inline-block;
			border-top: 4px solid #fff;
			padding: .75rem 1.25rem 0 1.25rem;
			span {
				display: block;
				margin-top: .25rem;
			}
		}
		@media (max-width: 991px) {
			padding-top: 6.25rem;
			padding-bottom: 1.75rem;
		}
		@media (max-width: 767px) {
			padding-top: 2.25rem;
			padding-bottom: 1.75rem;
		}
	}
}

.products-cases {
	.container {
		max-width: 1200px;
		padding-left: 15px;
		padding-right: 15px;
	}
	min-height: 450px;
	padding-top: 2.375rem;
	padding-bottom: 2.25rem;
	background-color: #F5F5F5;
	h3 {
		font-size: 1.4375rem;
		color: #4665A2;
		padding-bottom: 2.375rem;
		margin-bottom: 0;
		text-align: center;
	}

}

.other-cases {
	.container {
		max-width: 1200px;
		padding-left: 15px;
		padding-right: 15px;
	}
	min-height: 380px;
	padding-top: 2.5rem;
	padding-bottom: 3rem;
	background-color: #F2F93B;
	h4 {
		font-size: 1.25rem;
		font-weight: bold;
		color: #4665A2;
		padding-bottom: 2rem;
		margin-bottom: 0;
		text-align: center;
	}

}

.page-tags {
	.container {
		max-width: 1200px;
		padding-left: 1rem;
		padding-right: 1rem;
	}
	min-height: 9rem;
	padding-top: 2.375rem;
	background-color: #F2F93B;
	padding-bottom: 2.25rem;
	@media (max-width: 575px) {
		min-height: auto;
		padding-bottom: 1.5rem;
	}
	h3 {
		color: #666;
		font-size: 1.4375rem;
		padding-bottom: 2rem;
		margin-bottom: 0;
		text-align: center;
	}
	ul {
		padding-left: 0;
		list-style: none;
		margin: 0 auto;
		li {
			font-size: .875rem;
			font-weight: bold;
			display: inline;
			line-height: 2.0;
			margin-right: 1rem;
			a {
				color: #333;
			}
		}
	}

}

/* CONTENTS BOX SETTINGS
------------------------------ */
/* CONTENTS BOX BASE
------------------------------ */
.contents-box {
	margin-top: -70px;
		@media (max-width: 991px) {
			margin-top: 1.5rem;
		}
	position: relative;
	z-index: 100;
	color: #333;
	.container {
		max-width: 916px;
		margin: 0 auto;
	}
	.contents-wrapper {
		background-color: #fff;
		min-height: 800px;
		border-radius: 8px;
		box-shadow: 0 0 8px #666;
		padding-top: 3.125rem;
		padding-bottom: 3.125rem;
		@media (max-width: 575px) {
			padding-top: 2.25rem;
			padding-bottom: 2.25rem;
		}
	}
}

/* CONTENTS BOX DETAIL
------------------------------ */
.contents-box {
	margin-bottom: 3.75rem;
	.contents-wrapper {
		.page-lead {
			margin-bottom: 3.125rem;
			@media (max-width: 575px) {
				padding-left: 1rem;
				padding-right: 1rem;
			}
			.main-lead {
				color: #4665A2;
				text-align: center;
				font-size: 1.375rem;
				font-weight: bold;
				line-height: 1.8;
				margin-bottom: 3rem;
				span {
					display: block;
				}
				@media (max-width: 575px) {
					// text-align: left;
					line-height: 1.6;
					margin-bottom: 2.25rem;
					// font-size: 1.125rem;
					// font-weight: bold;
					span {
						display: inline;
					}
				}
			}
			.lead-content {
				text-align: center;
				p {
					margin-bottom: 1.5rem;
					line-height: 1.7;
					span {
						display: block;
					}
				}
				@media (max-width: 575px) {
					text-align: left;
					p {
						span {
							display: inline;
						}
					}
				}
			}
		}
		.primary-detail {
			// border-bottom: 6px solid #ddd;
			margin-bottom: 1rem;
			ul {
				padding-left: 18px;
				padding-right: 18px;
				list-style: none;
				li {
					text-align: center;
					padding-left: 18px;
					padding-right: 18px;
					img {
						margin-bottom: .8rem;
						max-width: 100%;
					}
					p {
						text-align: left;
						font-size: .875rem;
						color: #666;
					}
				}
			}
			.detail-title {
				font-size: 1rem;
				font-weight: bold;
				color: #4665A2;
				margin-bottom: 1rem;
			}
		}
	}

	.section-title {
		font-size: 1.125rem;
		margin-bottom: 1.5rem;
		font-weight: bold;
		color: #666;
		text-align: center;
	}

	.product-example {
		padding-top: 2.125rem;
		ul {
			padding-left: 3rem;
			// list-style: none;
			li {
				list-style-position: outside;
				line-height: 2.0;
				font-size: .875rem;
				color: #333;
				// padding-left: 1.5rem;
				// background: url(../images/common/bullet_square.png) 0 5px no-repeat;
			}
		}
		@media (max-width: 767px) {
			padding-left: 1rem;
			padding-right: 1rem;
			ul {
				padding-left: 1rem;
				li {
					line-height: 1.6;
					margin-bottom: .5rem;
					padding-right: 1.5rem;
					background-position: 0 2px;
				}
			}
		}
	}

}


/* TITLE BOX BASE
------------------------------ */
.title-box {
	margin-top: -70px;
	margin-bottom: 2.75rem;
	text-align: center;
		@media (max-width: 991px) {
			margin-top: 1.5rem;
		}
	position: relative;
	z-index: 100;
	color: #333;
	.container {
		max-width: 916px;
		margin: 0 auto;
	}
	.title-wrapper {
		background-color: #fff;
		min-height: 110px;
		border-radius: 8px;
		box-shadow: 0 0 8px 0 #666;
		padding-top: 3.125rem;
		// padding-bottom: 3.125rem;
		@media (max-width: 991px) {
			padding-top: 2.25rem;
			// padding-bottom: 2.25rem;
		}
		@media (max-width: 767px) {
			padding-bottom: .5rem;
		}
		@media (max-width: 575px) {
			padding-top: 1.5rem;
		}
	}
}

.title-box {
	.page-lead {
		.main-lead {
			line-height: 2.0;
			@media (max-width: 767px) {
				font-size: 1.25rem;
				line-height: 1.6;
				span {
					display: inline;
				}
			}
			span {
				display: block;
			}
		}
	}
}

.title-box + .contents-box {
	background-color: #fff;
	margin-top: -3.5rem;
	z-index: 200;
	padding-top: 3.5rem;
	@media (max-width: 767px) {
		padding-top: 2.25rem;
		.lead-content {
			text-align: left;
			padding-left: 1rem;
			padding-right: 1rem;
			p {
				span {
					display: inline;
				}
			}
		}
	}
}


.page-navigation {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	text-align: center;
	nav {
		display: inline-block;
		font-size: 1.4375rem;
		line-height: 1.4375rem;
		color: #4665A2;
		// font-weight: bold;
		a {
			color: #4665A2;
		&.prev {
			background-color: #F2F93B;
		}
		&.next {
			background-color: #F2F93B;
		}
		}
		a,
		span {
			line-height: 1.4375rem;
			padding-right: 1.125rem;
			padding-left: 1.125rem;
		}
	}
}


.btn-sdkdefault {
	border-radius: 0;
	font-size: 1rem;
	font-weight: bold;
	width: 270px;
	line-height: 60px;
	color: #3065a7;
	background: #fff url(../images/common/right_arrow_blue.png) right 20px top 50% no-repeat;
	background-color: #fff;
	border-color: #bebebe;
	border-width: 7px;
	box-sizing: border-box;
	padding: 0 0 0 0;

&:hover {
	color: #3065a7;
	background: #f2f93b url(../images/common/right_arrow_blue.png) right 20px top 50% no-repeat;
	border-color: #f2f93b;
	border-width: 7px;
	box-sizing: border-box;
}
}

.notfound {
	.contents-box {
		margin-top: -70px;
			@media (max-width: 991px) {
				margin-top: 1.5rem;
			}
		.container {
			max-width: 916px;
			margin: 0 auto;
		}
		.contents-wrapper {
			min-height: auto;
			padding-top: 3.125rem;
			padding-bottom: 3.125rem;
			@media (max-width: 575px) {
				padding-top: 2.25rem;
				padding-bottom: 2.25rem;
			}
		}
		.page-lead {
			text-align: center;
			img {
				max-width: 100%;
			}
		}
	}

	.section#nf-sitemap {
		background-color: #F5F5F5;
		min-height: 280px;
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
		.container {
			max-width: 1200px;
			padding-left: 0;
			padding-right: 0;
			@media (max-width: 767px) {
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}
		.section-title {
			text-align: center;
			color: #4665A2;
			font-weight: bold;
			font-size: 1.4375rem;
			margin: 0 auto 2.5rem;
			@media (max-width: 767px) {
				font-size: 1.25rem;
			}
		}
	}
}

/* WordPress Galleries Settings
/* Over Written
------------------------------ */
.gallery-item {
	display: inline-block;
	text-align: left;
	vertical-align: top;
	margin: 0 0 1rem;
	padding: 0 .25rem 0 .25rem;
	width: 50%;
}

.gallery-columns-1 .gallery-item {
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-item a,
.gallery-item a:hover,
.gallery-item a:focus,
.widget-area .gallery-item a,
.widget-area .gallery-item a:hover,
.widget-area .gallery-item a:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	background: none;
	display: inline-block;
	max-width: 100%;
}

.gallery-item a img {
	display: block;
	-webkit-transition: -webkit-filter 0.2s ease-in;
	transition: -webkit-filter 0.2s ease-in;
	transition: filter 0.2s ease-in;
	transition: filter 0.2s ease-in, -webkit-filter 0.2s ease-in;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	width: 100%;
	height: 100%;
}

.gallery-item a:hover img,
.gallery-item a:focus img {
	-webkit-filter: opacity(60%);
	filter: opacity(60%);
}

.gallery-caption {
	display: block;
	text-align: left;
	padding: 0 10px 0 0;
	margin-bottom: 0;
}

/* Gallery widget */

.gallery-columns-5 .gallery-caption,
.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
	display: none;
}

	.gallery-item {
		max-width: 25%;
	}

	.gallery-columns-1 .gallery-item {
		max-width: 100%;
	}

	.gallery-columns-2 .gallery-item {
		max-width: 50%;
	}

	.gallery-columns-3 .gallery-item {
		max-width: 33.33%;
	}

	.gallery-columns-4 .gallery-item {
		max-width: 25%;
	}

	.gallery-columns-5 .gallery-item {
		max-width: 20%;
	}

	.gallery-columns-6 .gallery-item {
		max-width: 16.66%;
	}

	.gallery-columns-7 .gallery-item {
		max-width: 14.28%;
	}

	.gallery-columns-8 .gallery-item {
		max-width: 12.5%;
	}

	.gallery-columns-9 .gallery-item {
		max-width: 11.11%;
	}




.search-result {
	.contents-box {
		margin-top: -70px;
			@media (max-width: 991px) {
				margin-top: 1.5rem;
			}
		.container {
			max-width: 916px;
			margin: 0 auto;
		}
		.contents-wrapper {
			min-height: auto;
			padding-top: 3.125rem;
			padding-bottom: 3.125rem;
			min-height: 400px;
			@media (max-width: 575px) {
				padding-top: 2.25rem;
				padding-bottom: 2.25rem;
			}
		}
		.page-lead {
			text-align: center;
			img {
				max-width: 100%;
			}
		}
	}

	.post-list {
		padding-bottom: 2.5rem;
		.post-item {
			border-top: 1px solid #ccc;
			padding-top: 1.5rem;
			padding-bottom: 1.75rem;
			padding-left: 1.25rem;
			padding-right: 1.25rem;
			&:last-of-type {
				border-bottom: 1px solid #ccc;
				margin-bottom: 2.5rem;
			}
			@media (max-width: 767px) {
				padding-top: 1rem;
			}
		}
		.post-title {
			h5 {
				font-weight: bold;
				font-size: 1.125rem;
				line-height: 1.4;
				margin-bottom: .5rem;
					@media (max-width: 767px) {
						font-size: 1rem;
						line-height: 1.2;
					}
				a {
					color: #333;
					width: 100%;
				}
			}
		}
		.post-meta {
			margin-bottom: .25rem;
			p {
				margin-bottom: 0;
				font-size: .8125rem;
			&.post-category {
				color: #4665A2;
			}
			}
		}
		.post-excerpt {
			p {
				margin-bottom: 0;
				font-size: .8125rem;
			}
		}

	}

}


.sitemap-in-page {
	.module-inner {
		max-width: 600px;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		margin: 0 auto;
	}
	h1 {
		text-align: center;
		color: #33669A;
		font-size: 1.375rem;
		margin-bottom: 1.875rem;
		font-weight: bold;
		@media (max-width: 767px) {
			span {
				display: block;
			}
		}
	}
	h2 {
		color: #037;
		font-size: 1rem;
		margin-bottom: 1rem;
		font-weight: bold;
	&:before {
			content: "◎";
			margin-right: .25rem;
		}
	}
	ul {
		li {
			a {
				color: #333;
			}
			ul {
				margin-top: .25rem;
				margin-bottom: 1.5rem;
			}
		}
	}
}

/* Contents View Overwritten
------------------------------ */
.pt-cv-wrapper {
	.pt-cv-view {
		.pt-cv-content-item.col-md-3.col-sm-12.col-xs-12 {
			.pt-cv-ifield {
				@media (max-width: 767px) {
					.pt-cv-href-thumbnail.pt-cv-thumb-default {
						img {
							// border: 1px solid #c00;
							display: block;
							margin: 0 auto 10px;
						}
					}
				}
			}
		}
	}
}