@charset "UTF-8";
/* ORIGINAL GLOBAL STYLES
-------------------------------------------------- */
/* FIXED FOOTER SETTINGS
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
  z-index: 1; }

body {
  /* Global default text color */
  color: #666;
  /* Margin bottom fot footer height */
  margin-bottom: 50px;
  /* Padding top for default header height */ }

strong {
  font-weight: normal; }

em {
  font-style: normal; }

.text-s {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; }

.text-ss {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese","ヒラギノ角ゴ ProN W3", Meiryo, sans-serif; }

/* BREADCRUMBS MODULE
------------------------------ */
.breadcrumbs .container {
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0; }

.breadcrumbs .breadcrumb {
  margin-bottom: 0;
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 10px; }

.breadcrumbs .breadcrumb-item {
  line-height: 1.0;
  font-size: .8125rem; }
  .breadcrumbs .breadcrumb-item + .breadcrumb-item::before {
    content: "";
    color: #666;
    padding-right: .75rem; }
  .breadcrumbs .breadcrumb-item.breadcrumb-item::after {
    content: ">";
    color: #666;
    padding-left: .75rem; }
  .breadcrumbs .breadcrumb-item.bread-root + .breadcrumb-item::before {
    content: "|";
    color: #666; }
  .breadcrumbs .breadcrumb-item.bread-root.breadcrumb-item::after {
    content: "";
    padding-left: .25rem; }
  .breadcrumbs .breadcrumb-item.bread-home + .breadcrumb-item::before {
    content: "|";
    color: #666; }
  .breadcrumbs .breadcrumb-item.bread-home.breadcrumb-item::after {
    content: "";
    padding-left: .25rem; }
  .breadcrumbs .breadcrumb-item a {
    color: #666; }
  .breadcrumbs .breadcrumb-item.active {
    color: #000; }
    .breadcrumbs .breadcrumb-item.active.breadcrumb-item::after {
      content: "";
      color: #666;
      padding-left: .5rem; }
    .breadcrumbs .breadcrumb-item.active.breadcrumb-item::before {
      padding-left: 0; }

/* FADE IN SCROLL TOP
------------------------------ */
#scrolltop {
  position: fixed;
  bottom: 68px;
  right: 10px;
  z-index: 1000; }
  #scrolltop a {
    background: #F2F93B;
    text-decoration: none;
    color: #fff;
    width: 48px;
    height: 48px;
    padding: 18px 14px;
    text-align: center;
    display: block;
    border-radius: 50%;
    opacity: 0.8; }
    #scrolltop a:hover {
      text-decoration: none;
      background: #F2F93B;
      opacity: 1.0; }
  #scrolltop .arrow {
    width: 20px;
    height: 20px;
    border: 5px solid;
    border-color: #4665A2 #4665A2 transparent transparent;
    transform: rotate(-45deg); }

/* NAVIBAR SETTINGS overwrite bootstrap default
-------------------------------------------------- */
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
  .navbar .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
    position: relative; }
  .navbar h1 {
    margin: 0;
    padding-bottom: 0;
    display: inline;
    line-height: 1.0; }
  .navbar.bg-light {
    background-color: #fff !important; }
  .navbar.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }

.navbar-brand {
  padding-top: 1.125rem;
  padding-left: .75rem;
  margin-right: 0;
  padding-bottom: 20px; }

@media (min-width: 992px) {
  .dropdown-toggle:after {
    content: none; } }

@media (min-width: 991px) {
  .nav-item form .form-control {
    width: 116px; } }

.nav-item.nav-search {
  background-color: #8DABCC;
  padding-top: 34px;
  padding: 38px 24px 14px 14px; }
  .nav-item.nav-search form .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    border-color: #8DABCC; }
  .nav-item.nav-search form .btn-default {
    width: 22px;
    min-height: 38px;
    background: #fff url(../images/common/icon_search_b.png) 10% 50% no-repeat;
    background-size: 90%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #8DABCC;
    border-left: 0; }
    .nav-item.nav-search form .btn-default:hover {
      background: #fff url(../images/common/icon_search_db.png) 10% 50% no-repeat; }
  @media (max-width: 575px) {
    .nav-item.nav-search form .form-inline .input-group {
      max-width: 100% !important; } }

.nav-link {
  font-size: .9375rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0;
  line-height: 1.0;
  border-left: 1px solid #666; }
  @media (max-width: 991px) {
    .nav-link {
      border-left: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
    padding: 55px 22px 6px 8px; } }

@media (max-width: 1199px) {
  .navbar .navbar-brand {
    padding-top: .8rem;
    padding-left: 0;
    padding-bottom: 11px;
    margin: 0 auto; }
  .navbar > .container {
    flex-direction: column; }
  .navbar-nav .nav-item .nav-link {
    padding: 16px 22px 16px 8px; }
  .navbar-nav .nav-item:first-of-type .nav-link {
    border-left: none; }
  .nav-item.nav-search {
    padding: 8px 14px 8px 14px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .navbar #header-nav {
    min-height: 140px;
    width: 100%; }
    .navbar #header-nav .navbar-nav {
      margin-top: 92px;
      width: 100%;
      flex-basis: 100%;
      justify-content: space-between; }
      .navbar #header-nav .navbar-nav .nav-item .nav-link {
        padding: 20px 22px 19px 8px; }
  .navbar .navbar-brand {
    position: absolute;
    top: 10px;
    left: 50%;
    right: 50%;
    margin-left: -122.5px;
    height: 51px;
    z-index: 800; }
  .navbar {
    padding-top: 0; } }

@media (max-width: 991px) {
  .navbar > .container {
    flex-direction: row; }
  .navbar .navbar-brand {
    padding-top: .625rem;
    padding-left: 1rem; }
  .navbar .navbar-collapse .navbar-nav {
    margin-top: 0; }
    .navbar .navbar-collapse .navbar-nav .nav-link {
      padding-left: 1.5rem;
      padding-right: 1.5rem; } }

.navbar-light .navbar-nav .nav-link {
  color: #666; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #8dabcc;
    border-left-color: #8dabcc; }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #8dabcc; }

.navbar-light .navbar-toggler {
  border: 3px solid #4665A2;
  border-radius: 0;
  margin-right: 1rem;
  color: #4665A2;
  border-color: #4665A2;
  background: transparent; }

.navbar-light .navbar-toggler-icon {
  background-image: url(../images/common/toggler-icon-bg.svg); }

.navbar-light .navbar-text {
  color: #666; }

/* DROPDOWN OVERWRITE
------------------------------ */
.dropdown-menu {
  padding: 0 0;
  margin: 0 0 0;
  font-size: 1rem;
  color: #fff;
  background-color: #666;
  opacity: 0.8;
  border: none;
  border-radius: 0; }

.dropdown-item {
  color: #fff;
  font-size: .9375rem;
  padding: 0.5rem 1.5rem; }
  @media (max-width: 991px) {
    .dropdown-item {
      padding: 1rem 2.5rem; } }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #333;
    text-decoration: none;
    background-color: #fff; }
  .dropdown-item.active, .dropdown-item:active {
    color: #333;
    text-decoration: none;
    background-color: #fff; }

/* STICKEY FOOTER SETTINGS
-------------------------------------------------- */
footer.footer {
  height: 300px;
  background-color: #fff;
  color: #666;
  position: relative; }
  footer.footer .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0; }
  @media (max-width: 991px) {
    footer.footer {
      height: auto; } }

.footer-contact {
  background-color: #8DABCC; }
  .footer-contact .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0; }
  .footer-contact ul {
    padding-left: 217px;
    list-style: none; }
    .footer-contact ul li {
      height: 40px;
      line-height: 40px;
      color: #fff;
      font-size: 1.375rem; }
    .footer-contact ul a {
      color: #fff; }
      .footer-contact ul a:hover, .footer-contact ul a:focus {
        text-decoration: none;
        cursor: pointer; }
    @media (max-width: 991px) {
      .footer-contact ul {
        padding-left: 0; }
        .footer-contact ul li {
          text-align: center;
          font-size: 1.25rem; } }

.footer-profiles {
  padding-top: 2rem; }
  @media (max-width: 990px) {
    .footer-profiles {
      padding-top: 0; } }
  .footer-profiles .footer-id {
    padding-left: 10px; }
    @media (max-width: 990px) {
      .footer-profiles .footer-id {
        padding-left: 2rem; } }
  .footer-profiles .footer-logo {
    margin-bottom: 1.25rem; }
    @media (max-width: 990px) {
      .footer-profiles .footer-logo {
        display: none;
        margin-bottom: 0; } }
  .footer-profiles .footer-address {
    font-size: .8125rem;
    font-weight: bold;
    margin-bottom: .875rem; }
    @media (max-width: 991px) {
      .footer-profiles .footer-address {
        margin-bottom: 2.5rem; } }
    .footer-profiles .footer-address .fa-cname {
      display: block; }
  .footer-profiles .footer-sitemap {
    font-size: .8125rem; }
    @media (max-width: 991px) {
      .footer-profiles .footer-sitemap {
        display: none; } }
    .footer-profiles .footer-sitemap p {
      padding: 0;
      margin: 0;
      line-height: 2.0;
      font-weight: bold;
      padding-bottom: .5rem; }
    .footer-profiles .footer-sitemap a {
      color: #333; }
    .footer-profiles .footer-sitemap ul {
      padding-left: 0;
      list-style: none; }
      .footer-profiles .footer-sitemap ul li {
        line-height: 2.0; }
        .footer-profiles .footer-sitemap ul li.col-12 {
          padding-left: 0; }
      .footer-profiles .footer-sitemap ul.no-child li {
        font-weight: bold; }

.stickey-footer {
  height: 50px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1200; }

.footer-copy {
  height: 30px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%; }
  .footer-copy .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px; }
  .footer-copy p {
    line-height: 1.0;
    font-size: .75rem;
    margin-bottom: 0;
    padding-bottom: 0; }

.footer-links {
  height: 50px;
  line-height: 50px; }
  .footer-links .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0; }
  .footer-links .flink {
    text-align: center; }
    .footer-links .flink a {
      font-size: .9375rem;
      margin: 0;
      padding: 0;
      height: 50px;
      line-height: 50px;
      font-weight: bold;
      color: #fff;
      display: inline-block;
      width: 100%; }
      .footer-links .flink a:hover {
        text-decoration: none; }
    .footer-links .flink.flink-faq {
      background-color: #4665A2;
      z-index: 1050; }
    .footer-links .flink.flink-inquiry {
      background-color: #037;
      z-index: 1051; }

.flbg {
  height: 50px;
  margin-top: -50px;
  z-index: 20; }

.flbg-faq {
  background-color: #4665A2;
  z-index: 20; }

.flbg-inquiry {
  background-color: #037;
  z-index: 20; }

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
  background-color: #000; }

.carousel-caption {
  z-index: 10;
  top: 10rem; }

/* Declare heights because of positioning of img element */
.carousel-item img {
  display: block;
  margin: 0 auto;
  max-width: 100%; }

.carousel-item .slideimage img {
  max-width: 100%; }

.carousel-item .slidecaption {
  max-width: 400px; }

.page-hero {
  background-color: #8DABCC; }
  .page-hero .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0; }
  @media (max-width: 1199px) {
    .page-hero .hero-image img {
      max-width: 100%; } }
  .page-hero .hero-title {
    background-color: #4665A2;
    color: #fff;
    padding-top: 8.75rem;
    text-align: center; }
    .page-hero .hero-title .class-title {
      font-size: 1.875rem;
      margin-bottom: .75rem; }
    .page-hero .hero-title .page-title {
      font-size: 1rem;
      font-weight: bold;
      display: inline-block;
      border-top: 4px solid #fff;
      padding: .75rem 1.25rem 0 1.25rem; }
      .page-hero .hero-title .page-title span {
        display: block;
        margin-top: .25rem; }
    @media (max-width: 991px) {
      .page-hero .hero-title {
        padding-top: 6.25rem;
        padding-bottom: 1.75rem; } }
    @media (max-width: 767px) {
      .page-hero .hero-title {
        padding-top: 2.25rem;
        padding-bottom: 1.75rem; } }

.products-cases {
  min-height: 450px;
  padding-top: 2.375rem;
  padding-bottom: 2.25rem;
  background-color: #F5F5F5; }
  .products-cases .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; }
  .products-cases h3 {
    font-size: 1.4375rem;
    color: #4665A2;
    padding-bottom: 2.375rem;
    margin-bottom: 0;
    text-align: center; }

.other-cases {
  min-height: 380px;
  padding-top: 2.5rem;
  padding-bottom: 3rem;
  background-color: #F2F93B; }
  .other-cases .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; }
  .other-cases h4 {
    font-size: 1.25rem;
    font-weight: bold;
    color: #4665A2;
    padding-bottom: 2rem;
    margin-bottom: 0;
    text-align: center; }

.page-tags {
  min-height: 9rem;
  padding-top: 2.375rem;
  background-color: #F2F93B;
  padding-bottom: 2.25rem; }
  .page-tags .container {
    max-width: 1200px;
    padding-left: 1rem;
    padding-right: 1rem; }
  @media (max-width: 575px) {
    .page-tags {
      min-height: auto;
      padding-bottom: 1.5rem; } }
  .page-tags h3 {
    color: #666;
    font-size: 1.4375rem;
    padding-bottom: 2rem;
    margin-bottom: 0;
    text-align: center; }
  .page-tags ul {
    padding-left: 0;
    list-style: none;
    margin: 0 auto; }
    .page-tags ul li {
      font-size: .875rem;
      font-weight: bold;
      display: inline;
      line-height: 2.0;
      margin-right: 1rem; }
      .page-tags ul li a {
        color: #333; }

/* CONTENTS BOX SETTINGS
------------------------------ */
/* CONTENTS BOX BASE
------------------------------ */
.contents-box {
  margin-top: -70px;
  position: relative;
  z-index: 100;
  color: #333; }
  @media (max-width: 991px) {
    .contents-box {
      margin-top: 1.5rem; } }
  .contents-box .container {
    max-width: 916px;
    margin: 0 auto; }
  .contents-box .contents-wrapper {
    background-color: #fff;
    min-height: 800px;
    border-radius: 8px;
    box-shadow: 0 0 8px #666;
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
    @media (max-width: 575px) {
      .contents-box .contents-wrapper {
        padding-top: 2.25rem;
        padding-bottom: 2.25rem; } }

/* CONTENTS BOX DETAIL
------------------------------ */
.contents-box {
  margin-bottom: 3.75rem; }
  .contents-box .contents-wrapper .page-lead {
    margin-bottom: 3.125rem; }
    @media (max-width: 575px) {
      .contents-box .contents-wrapper .page-lead {
        padding-left: 1rem;
        padding-right: 1rem; } }
    .contents-box .contents-wrapper .page-lead .main-lead {
      color: #4665A2;
      text-align: center;
      font-size: 1.375rem;
      font-weight: bold;
      line-height: 1.8;
      margin-bottom: 3rem; }
      .contents-box .contents-wrapper .page-lead .main-lead span {
        display: block; }
      @media (max-width: 575px) {
        .contents-box .contents-wrapper .page-lead .main-lead {
          line-height: 1.6;
          margin-bottom: 2.25rem; }
          .contents-box .contents-wrapper .page-lead .main-lead span {
            display: inline; } }
    .contents-box .contents-wrapper .page-lead .lead-content {
      text-align: center; }
      .contents-box .contents-wrapper .page-lead .lead-content p {
        margin-bottom: 1.5rem;
        line-height: 1.7; }
        .contents-box .contents-wrapper .page-lead .lead-content p span {
          display: block; }
      @media (max-width: 575px) {
        .contents-box .contents-wrapper .page-lead .lead-content {
          text-align: left; }
          .contents-box .contents-wrapper .page-lead .lead-content p span {
            display: inline; } }
  .contents-box .contents-wrapper .primary-detail {
    margin-bottom: 1rem; }
    .contents-box .contents-wrapper .primary-detail ul {
      padding-left: 18px;
      padding-right: 18px;
      list-style: none; }
      .contents-box .contents-wrapper .primary-detail ul li {
        text-align: center;
        padding-left: 18px;
        padding-right: 18px; }
        .contents-box .contents-wrapper .primary-detail ul li img {
          margin-bottom: .8rem;
          max-width: 100%; }
        .contents-box .contents-wrapper .primary-detail ul li p {
          text-align: left;
          font-size: .875rem;
          color: #666; }
    .contents-box .contents-wrapper .primary-detail .detail-title {
      font-size: 1rem;
      font-weight: bold;
      color: #4665A2;
      margin-bottom: 1rem; }
  .contents-box .section-title {
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    color: #666;
    text-align: center; }
  .contents-box .product-example {
    padding-top: 2.125rem; }
    .contents-box .product-example ul {
      padding-left: 3rem; }
      .contents-box .product-example ul li {
        list-style-position: outside;
        line-height: 2.0;
        font-size: .875rem;
        color: #333; }
    @media (max-width: 767px) {
      .contents-box .product-example {
        padding-left: 1rem;
        padding-right: 1rem; }
        .contents-box .product-example ul {
          padding-left: 1rem; }
          .contents-box .product-example ul li {
            line-height: 1.6;
            margin-bottom: .5rem;
            padding-right: 1.5rem;
            background-position: 0 2px; } }

/* TITLE BOX BASE
------------------------------ */
.title-box {
  margin-top: -70px;
  margin-bottom: 2.75rem;
  text-align: center;
  position: relative;
  z-index: 100;
  color: #333; }
  @media (max-width: 991px) {
    .title-box {
      margin-top: 1.5rem; } }
  .title-box .container {
    max-width: 916px;
    margin: 0 auto; }
  .title-box .title-wrapper {
    background-color: #fff;
    min-height: 110px;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 #666;
    padding-top: 3.125rem; }
    @media (max-width: 991px) {
      .title-box .title-wrapper {
        padding-top: 2.25rem; } }
    @media (max-width: 767px) {
      .title-box .title-wrapper {
        padding-bottom: .5rem; } }
    @media (max-width: 575px) {
      .title-box .title-wrapper {
        padding-top: 1.5rem; } }

.title-box .page-lead .main-lead {
  line-height: 2.0; }
  @media (max-width: 767px) {
    .title-box .page-lead .main-lead {
      font-size: 1.25rem;
      line-height: 1.6; }
      .title-box .page-lead .main-lead span {
        display: inline; } }
  .title-box .page-lead .main-lead span {
    display: block; }

.title-box + .contents-box {
  background-color: #fff;
  margin-top: -3.5rem;
  z-index: 200;
  padding-top: 3.5rem; }
  @media (max-width: 767px) {
    .title-box + .contents-box {
      padding-top: 2.25rem; }
      .title-box + .contents-box .lead-content {
        text-align: left;
        padding-left: 1rem;
        padding-right: 1rem; }
        .title-box + .contents-box .lead-content p span {
          display: inline; } }

.page-navigation {
  text-align: center; }
  .page-navigation .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0; }
  .page-navigation nav {
    display: inline-block;
    font-size: 1.4375rem;
    line-height: 1.4375rem;
    color: #4665A2; }
    .page-navigation nav a {
      color: #4665A2; }
      .page-navigation nav a.prev {
        background-color: #F2F93B; }
      .page-navigation nav a.next {
        background-color: #F2F93B; }
    .page-navigation nav a,
    .page-navigation nav span {
      line-height: 1.4375rem;
      padding-right: 1.125rem;
      padding-left: 1.125rem; }

.btn-sdkdefault {
  border-radius: 0;
  font-size: 1rem;
  font-weight: bold;
  width: 270px;
  line-height: 60px;
  color: #3065a7;
  background: #fff url(../images/common/right_arrow_blue.png) right 20px top 50% no-repeat;
  background-color: #fff;
  border-color: #bebebe;
  border-width: 7px;
  box-sizing: border-box;
  padding: 0 0 0 0; }
  .btn-sdkdefault:hover {
    color: #3065a7;
    background: #f2f93b url(../images/common/right_arrow_blue.png) right 20px top 50% no-repeat;
    border-color: #f2f93b;
    border-width: 7px;
    box-sizing: border-box; }

.notfound .contents-box {
  margin-top: -70px; }
  @media (max-width: 991px) {
    .notfound .contents-box {
      margin-top: 1.5rem; } }
  .notfound .contents-box .container {
    max-width: 916px;
    margin: 0 auto; }
  .notfound .contents-box .contents-wrapper {
    min-height: auto;
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
    @media (max-width: 575px) {
      .notfound .contents-box .contents-wrapper {
        padding-top: 2.25rem;
        padding-bottom: 2.25rem; } }
  .notfound .contents-box .page-lead {
    text-align: center; }
    .notfound .contents-box .page-lead img {
      max-width: 100%; }

.notfound .section#nf-sitemap {
  background-color: #F5F5F5;
  min-height: 280px;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }
  .notfound .section#nf-sitemap .container {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0; }
    @media (max-width: 767px) {
      .notfound .section#nf-sitemap .container {
        padding-left: 1rem;
        padding-right: 1rem; } }
  .notfound .section#nf-sitemap .section-title {
    text-align: center;
    color: #4665A2;
    font-weight: bold;
    font-size: 1.4375rem;
    margin: 0 auto 2.5rem; }
    @media (max-width: 767px) {
      .notfound .section#nf-sitemap .section-title {
        font-size: 1.25rem; } }

/* WordPress Galleries Settings
/* Over Written
------------------------------ */
.gallery-item {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin: 0 0 1rem;
  padding: 0 .25rem 0 .25rem;
  width: 50%; }

.gallery-columns-1 .gallery-item {
  width: 100%; }

.gallery-columns-2 .gallery-item {
  max-width: 50%; }

.gallery-item a,
.gallery-item a:hover,
.gallery-item a:focus,
.widget-area .gallery-item a,
.widget-area .gallery-item a:hover,
.widget-area .gallery-item a:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  display: inline-block;
  max-width: 100%; }

.gallery-item a img {
  display: block;
  -webkit-transition: -webkit-filter 0.2s ease-in;
  transition: -webkit-filter 0.2s ease-in;
  transition: filter 0.2s ease-in;
  transition: filter 0.2s ease-in, -webkit-filter 0.2s ease-in;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: 100%; }

.gallery-item a:hover img,
.gallery-item a:focus img {
  -webkit-filter: opacity(60%);
  filter: opacity(60%); }

.gallery-caption {
  display: block;
  text-align: left;
  padding: 0 10px 0 0;
  margin-bottom: 0; }

/* Gallery widget */
.gallery-columns-5 .gallery-caption,
.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none; }

.gallery-item {
  max-width: 25%; }

.gallery-columns-1 .gallery-item {
  max-width: 100%; }

.gallery-columns-2 .gallery-item {
  max-width: 50%; }

.gallery-columns-3 .gallery-item {
  max-width: 33.33%; }

.gallery-columns-4 .gallery-item {
  max-width: 25%; }

.gallery-columns-5 .gallery-item {
  max-width: 20%; }

.gallery-columns-6 .gallery-item {
  max-width: 16.66%; }

.gallery-columns-7 .gallery-item {
  max-width: 14.28%; }

.gallery-columns-8 .gallery-item {
  max-width: 12.5%; }

.gallery-columns-9 .gallery-item {
  max-width: 11.11%; }

.search-result .contents-box {
  margin-top: -70px; }
  @media (max-width: 991px) {
    .search-result .contents-box {
      margin-top: 1.5rem; } }
  .search-result .contents-box .container {
    max-width: 916px;
    margin: 0 auto; }
  .search-result .contents-box .contents-wrapper {
    min-height: auto;
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    min-height: 400px; }
    @media (max-width: 575px) {
      .search-result .contents-box .contents-wrapper {
        padding-top: 2.25rem;
        padding-bottom: 2.25rem; } }
  .search-result .contents-box .page-lead {
    text-align: center; }
    .search-result .contents-box .page-lead img {
      max-width: 100%; }

.search-result .post-list {
  padding-bottom: 2.5rem; }
  .search-result .post-list .post-item {
    border-top: 1px solid #ccc;
    padding-top: 1.5rem;
    padding-bottom: 1.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
    .search-result .post-list .post-item:last-of-type {
      border-bottom: 1px solid #ccc;
      margin-bottom: 2.5rem; }
    @media (max-width: 767px) {
      .search-result .post-list .post-item {
        padding-top: 1rem; } }
  .search-result .post-list .post-title h5 {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.4;
    margin-bottom: .5rem; }
    @media (max-width: 767px) {
      .search-result .post-list .post-title h5 {
        font-size: 1rem;
        line-height: 1.2; } }
    .search-result .post-list .post-title h5 a {
      color: #333;
      width: 100%; }
  .search-result .post-list .post-meta {
    margin-bottom: .25rem; }
    .search-result .post-list .post-meta p {
      margin-bottom: 0;
      font-size: .8125rem; }
      .search-result .post-list .post-meta p.post-category {
        color: #4665A2; }
  .search-result .post-list .post-excerpt p {
    margin-bottom: 0;
    font-size: .8125rem; }

.sitemap-in-page .module-inner {
  max-width: 600px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto; }

.sitemap-in-page h1 {
  text-align: center;
  color: #33669A;
  font-size: 1.375rem;
  margin-bottom: 1.875rem;
  font-weight: bold; }
  @media (max-width: 767px) {
    .sitemap-in-page h1 span {
      display: block; } }

.sitemap-in-page h2 {
  color: #037;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: bold; }
  .sitemap-in-page h2:before {
    content: "◎";
    margin-right: .25rem; }

.sitemap-in-page ul li a {
  color: #333; }

.sitemap-in-page ul li ul {
  margin-top: .25rem;
  margin-bottom: 1.5rem; }

/* Contents View Overwritten
------------------------------ */
@media (max-width: 767px) {
  .pt-cv-wrapper .pt-cv-view .pt-cv-content-item.col-md-3.col-sm-12.col-xs-12 .pt-cv-ifield .pt-cv-href-thumbnail.pt-cv-thumb-default img {
    display: block;
    margin: 0 auto 10px; } }
